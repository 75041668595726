import { isUndefined } from '@iheartradio/web.utilities';

export function getDebug() {
  if (isUndefined(globalThis.window)) {
    return false;
  }

  const debug = new URL(window.location.href).searchParams.get('debug');

  if (!debug) {
    return false;
  }

  return debug.includes('true') || debug.includes('playback');
}
