import { initContract } from '@ts-rest/core';

import { HttpMethods } from '../../../httpUtils/constants.js';
import type { V3 } from '../../../types/poweramp.js';
import { implement } from '../../implement.js';
import {
  limitSchema,
  numberIdSchema,
  offsetSchema,
} from '../../schemas/common.js';

const c = initContract();

export const profilesContract = c.router(
  {
    putProfileGenres: {
      method: HttpMethods.Put,
      path: '/tasteProfile/genres',
      body: c.type<V3.UpdateGenres.RequestBody>(),
      responses: {
        204: c.type<V3.UpdateGenres.ResponseBody>(),
      },
    },

    getFollowedArtists: {
      method: HttpMethods.Get,
      path: '/follows/artist',
      query: implement<V3.GetFollowedArtistStations.RequestQuery>().from({
        limit: limitSchema,
        offset: offsetSchema,
      }),
      responses: {
        200: c.type<V3.GetFollowedArtistStations.ResponseBody>(),
      },
    },

    followArtist: {
      method: HttpMethods.Put,
      path: '/follows/artist',
      body: c.type<V3.AddArtistStationIfNotExistsAndFollow.RequestBody>(),
      responses: {
        201: c.type<V3.AddArtistStationIfNotExistsAndFollow.ResponseBody>(),
        204: c.type<never>(),
      },
    },

    unfollowArtist: {
      method: HttpMethods.Delete,
      path: '/follows/artist/:artistId',
      body: c.type<never>(),
      pathParams: implement<V3.UnfollowArtist.RequestParams>().from({
        artistId: numberIdSchema,
      }),
      responses: {
        204: c.type<never>(),
      },
    },

    getIsArtistFollowed: {
      method: HttpMethods.Get,
      path: '/follows/artist/:artistId',
      pathParams: implement<V3.IsArtistFollowed.RequestParams>().from({
        artistId: numberIdSchema,
      }),
      responses: {
        204: c.type<never>(),
        404: c.type<never>(),
      },
    },

    getFollowedLiveStations: {
      method: HttpMethods.Get,
      path: '/follows/live',
      query: implement<V3.GetFollowedLiveStations.RequestQuery>().from({
        limit: limitSchema,
        offset: offsetSchema,
      }),
      responses: {
        200: c.type<V3.GetFollowedLiveStations.ResponseBody>(),
      },
    },

    followLiveStation: {
      method: HttpMethods.Put,
      path: '/follows/live',
      body: c.type<V3.AddLiveStationIfNotExistsAndFollow.RequestBody>(),
      responses: {
        201: c.type<V3.AddLiveStationIfNotExistsAndFollow.ResponseBody>(),
        204: c.type<never>(),
      },
    },

    unfollowLiveStation: {
      method: HttpMethods.Delete,
      path: '/follows/live/:stationId',
      body: c.type<never>(),
      pathParams: implement<V3.UnfollowLiveStation.RequestParams>().from({
        stationId: numberIdSchema,
      }),
      responses: {
        200: c.type<never>(),
        204: c.type<never>(),
      },
    },

    getIsLiveStationFollowed: {
      method: HttpMethods.Get,
      path: '/follows/live/:liveStationId',
      pathParams: implement<V3.IsLiveStationFollowed.RequestParams>().from({
        liveStationId: numberIdSchema,
      }),
      responses: {
        204: c.type<V3.IsLiveStationFollowed.ResponseBody>(),
        404: c.type<never>(),
      },
    },
  },
  {
    pathPrefix: '/profiles',
  },
);
