import type * as Analytics from '@iheartradio/web.analytics';
import type { AmpClientOptions } from '@iheartradio/web.api/amp';
import type { Logger } from '@iheartradio/web.utilities/create-logger';
import { type ReactNode, useEffect } from 'react';

import { createPlayer } from './player:create.js';
import { createAnalyticsSubscription } from './player:subscription:analytics.js';
import { createJWPlayerSubscription } from './player:subscription:jw-player.js';
import { createKeyboardControlsSubscription } from './player:subscription:keyboard-controls.js';
import { createLoggingSubscription } from './player:subscription:logging.js';
import { createMediaSessionSubscription } from './player:subscription:media-session.js';
import { createRoyaltyReportingSubscription } from './player:subscription:royalty-reporting.js';
import * as Playback from './player:types.js';
import { createContext } from './react:create-context.js';
import { SubscribeToPlayerState } from './react:subscribe-to-player-state.js';
import { logger as defaultLogger } from './utility:default-logger.js';
import type { ExtendedError } from './utility:extended-error.js';
import { getDebug } from './utility:get-debug.js';

export function createReactPlayback<Resolvers extends Playback.Resolvers<any>>({
  api,
  debug = getDebug(),
  logger = defaultLogger,
  resolvers,
  analytics = {} as Analytics.Analytics.Analytics,
}: {
  api: Playback.Api;
  debug?: boolean;
  logger?: Logger;
  resolvers: Resolvers;
  analytics: Analytics.Analytics.Analytics;
}) {
  type Station = Parameters<Resolvers[keyof Resolvers]['load']>[1];
  type State = Omit<
    Playback.PlayerState<Station>,
    'errors' | 'metadata' | 'time'
  >;

  const player = createPlayer<Resolvers, Station>({
    api,
    resolvers,
  });

  const ads = player.getAds();

  const state = player.getState();

  const time = player.getTime();

  const jwPlayer = createJWPlayerSubscription<Station>(player, api, logger);

  const keyboardControls = createKeyboardControlsSubscription<Station>(player);

  const mediaSession = createMediaSessionSubscription<Resolvers, Station>({
    player,
    resolvers,
  });

  const royaltyReporting = createRoyaltyReportingSubscription<
    Resolvers,
    Station
  >({
    api,
    resolvers,
    state,
  });

  const analyticsReporting = createAnalyticsSubscription<Station>({
    state,
    timeState: time,
    analytics,
  });

  player.subscribe(jwPlayer);
  player.subscribe(keyboardControls);
  player.subscribe(mediaSession);
  player.subscribe(royaltyReporting);
  player.subscribe(analyticsReporting);

  if (debug) {
    logger.enable();

    player.subscribe(createLoggingSubscription('player', logger));

    jwPlayer.subscribe(
      createLoggingSubscription('player:subscription:jw-player', logger),
    );

    keyboardControls.subscribe(
      createLoggingSubscription(
        'player:subscription:keyboard-controls',
        logger,
      ),
    );

    mediaSession.subscribe(
      createLoggingSubscription('player:subscription:media-session', logger),
    );

    royaltyReporting.subscribe(
      createLoggingSubscription(
        'player:subscription:royalty-reporting',
        logger,
      ),
    );

    analyticsReporting.subscribe(
      createLoggingSubscription('player:subscription:analytics', logger),
    );

    ads.subscribe({
      set(_payload, key, value) {
        const prefix = `ads:subscription.set(${key}) => `;

        const args = JSON.stringify(value);

        const message = [prefix, args].join('');

        const logType =
          message.toLowerCase().includes('error') ? 'error' : 'log';

        logger[logType](message, { arguments: args });
      },
    });

    for (const [key, resolver] of Object.entries(resolvers)) {
      resolver.subscribe(
        createLoggingSubscription(`player:resolver:${key}`, logger),
      );
    }
  }

  const {
    errors: _errors,
    metadata,
    time: _time,
    ...rest
  } = state.deserialize();

  const AdsContext = createContext<Playback.Ads>(
    'Playback.Ads',
    ads.deserialize(),
  );

  const ErrorContext = createContext<Error[] | ExtendedError<string>[] | null>(
    'Playback.Error',
    null,
  );
  const MetadataContext = createContext<Playback.Metadata>(
    'Playback.Metadata',
    metadata,
  );
  const StateContext = createContext<State>('Playback.State', rest);
  const TimeContext = createContext<Playback.Time>(
    'Playback.Time',
    time.deserialize().time,
  );
  /**
   *
   * LiveInStreamAdTargeting
   * CustomInStreamAdTargeting
   * @returns
   */

  function PlaybackProvider({
    children,
    adsEnabled = true,
    apiConfig,
    dfpInstanceId,
    environment = 'prod',
    pageName = 'home',
    subscriptionType = 'free',
    lsid = '',
    podcastTritonTokenEnabled = false,
  }: {
    children: ReactNode;
    apiConfig: AmpClientOptions;
    adsEnabled?: boolean;
    dfpInstanceId?: number | null;
    environment?: string;
    pageName?: string;
    subscriptionType?: string;
    lsid?: string;
    podcastTritonTokenEnabled?: boolean;
  }) {
    useEffect(() => {
      api.setConfig(apiConfig);
    }, [apiConfig]);

    useEffect(() => {
      ads.set('dfpInstanceId', dfpInstanceId);
      ads.set('env', environment);
      ads.set('enabled', adsEnabled);
      ads.set('subscriptionType', subscriptionType);
    }, [dfpInstanceId, environment, adsEnabled, subscriptionType]);

    useEffect(() => {
      state.set('pageName', pageName);
    }, [pageName]);

    useEffect(() => {
      state.set('lsid', lsid);
    }, [lsid]);

    useEffect(() => {
      state.set('podcastTritonTokenEnabled', podcastTritonTokenEnabled);
    }, [podcastTritonTokenEnabled]);

    return (
      <ErrorContext.Provider>
        <StateContext.Provider>
          <AdsContext.Provider>
            <TimeContext.Provider>
              <MetadataContext.Provider>
                <SubscribeToPlayerState
                  ads={ads}
                  AdsContext={AdsContext}
                  ErrorContext={ErrorContext}
                  MetadataContext={MetadataContext}
                  state={state}
                  StateContext={StateContext}
                  time={time}
                  TimeContext={TimeContext}
                />
                {children}
              </MetadataContext.Provider>
            </TimeContext.Provider>
          </AdsContext.Provider>
        </StateContext.Provider>
      </ErrorContext.Provider>
    );
  }

  return {
    PlaybackProvider,

    useAds() {
      const [ads] = AdsContext.useContext();
      return {
        ...ads,
        adBreak: {
          [Playback.AdPlayerStatus.Buffering]: true,
          [Playback.AdPlayerStatus.Paused]: true,
          [Playback.AdPlayerStatus.Playing]: true,
          [Playback.AdPlayerStatus.Streaming]: true,
          [Playback.AdPlayerStatus.Done]: false,
          [Playback.AdPlayerStatus.Idle]: false,
        }[ads.status],
      };
    },

    useError() {
      const [error] = ErrorContext.useContext();
      return error;
    },

    useMetadata() {
      const [metadata] = MetadataContext.useContext();
      return metadata;
    },

    usePlayer<T extends Playback.Station>() {
      return player as Playback.Player<T>;
    },

    useState() {
      const [state] = StateContext.useContext();
      return state;
    },

    useTime() {
      const [time] = TimeContext.useContext();
      return time;
    },
  } as const;
}
