/// <reference types="jwplayer" />

import type { HTTPError } from '@iheartradio/web.api';
import {
  createSafeInterval,
  debounce,
  isBlank,
  isNull,
  isUndefined,
  loadScript,
} from '@iheartradio/web.utilities';
import {
  type CreateEmitter,
  createEmitter,
} from '@iheartradio/web.utilities/create-emitter';
import type { Logger } from '@iheartradio/web.utilities/create-logger';

import { PlayerError, PlayerErrorCode } from './player:error.js';
import {
  createPlayerEventsHandlers,
  LIVE_META_INTERVAL_KEY,
} from './player:subscription:jw-player-events.js';
import * as Playback from './player:types.js';
import { normalizeMetadata } from './utility:normalize-metadata.js';

export function createJWPlayerSubscription<Station extends Playback.Station>(
  player: Playback.Player<Station>,
  amp: Playback.Api,
  logger: Logger,
): CreateEmitter.Emitter<CreateEmitter.Subscription<Playback.Player<Station>>> {
  const SafeInterval = createSafeInterval(logger);

  let firstPlay = true;
  let jwplayer: jwplayer.JWPlayer;
  const { api } = amp;

  let doNotQueryAmp = false;

  function isAdBreak() {
    const { status: adsStatus } = player.getAds().deserialize();

    if (
      [
        Playback.AdPlayerStatus.Buffering,
        Playback.AdPlayerStatus.Playing,
        Playback.AdPlayerStatus.Paused,
        Playback.AdPlayerStatus.Streaming,
      ].includes(adsStatus)
    ) {
      return true;
    }
    return false;
  }

  async function setCurrentTrackMeta({
    streamId,
    stationMeta,
  }: {
    streamId: number;
    stationMeta: Readonly<Playback.QueueItem['meta']>;
  }) {
    if (!isAdBreak()) {
      const metadata = await getCurrentTrackMeta({ streamId });
      const type = metadata?.type ?? Playback.MetadataType.Station;
      const data: Playback.QueueItem['meta'] = {
        ...stationMeta,
        ...metadata?.data,
      };
      player.setMetadata({
        type,
        data,
      });
    }
  }

  async function getCurrentTrackMeta({ streamId }: { streamId: number }) {
    if (!doNotQueryAmp) {
      const metadata = await api.v3.livemeta
        .getCurrentTrackMeta({
          params: { streamId },
        })
        .then(({ body }) => body)
        .catch(async (error: HTTPError) => {
          const { response } = error;

          if (response) {
            switch (response.status) {
              case 404:
              case 410:
              case 424: {
                SafeInterval.clear(LIVE_META_INTERVAL_KEY);
                doNotQueryAmp = true;
                break;
              }
              case 422: {
                const blob = await response.clone().blob();
                const text = await blob.text();
                try {
                  return JSON.parse(text);
                } catch {
                  SafeInterval.clear(LIVE_META_INTERVAL_KEY);
                  doNotQueryAmp = true;
                  break;
                }
              }
            }
          }
        });

      if (isBlank(metadata)) {
        return;
      }

      const {
        artistId,
        artist: artistName,
        trackId,
        title: trackName,
      } = metadata;

      if (
        artistId &&
        artistName &&
        trackId &&
        trackName &&
        Number(artistId) > -1 &&
        Number(trackId) > -1
      ) {
        // Remove undefined values from `normalizeMetadata` so that they don't override "defaults" from
        // the station meta (i.e., "subtitle" (which is the station name))
        const trackMeta = Object.fromEntries(
          Object.entries(
            normalizeMetadata({ artistId, artistName, trackId, trackName }),
          ).reduce(
            (accumulator, [key, value]) => {
              if (!isUndefined(value)) {
                accumulator.push([key, value]);
              }
              return accumulator;
            },
            [] as [string, unknown][],
          ),
        );

        return {
          type: Playback.MetadataType.InStream,
          data: trackMeta,
        };
      }
    }
  }

  function load(state: Playback.PlayerState<Station> | null) {
    if (isNull(state) || isNull(state.station) || isUndefined(state.station)) {
      return;
    }

    const item = state.queue[state.index];
    const itemUrl = new URL(item.url);
    itemUrl.searchParams.set('modTime', `${Date.now() / 1000}`);

    const isEpisode = item.type === Playback.QueueItemType.Episode;

    const playerPlaylist =
      state.station.type === Playback.StationType.Live ?
        [
          {
            sources: state.queue.reduce(
              (accumulator, queueItem) => {
                const itemUrl = new URL(queueItem.url);
                itemUrl.searchParams.set('modTime', `${Date.now() / 1000}`);
                if (queueItem.format) {
                  accumulator.push({
                    file: itemUrl.toString(),
                    type: queueItem.format,
                  });
                }
                return accumulator;
              },
              [] as Record<string, string>[],
            ),
          },
        ]
      : [
          {
            file: itemUrl.toString(),
            preload: 'auto',
            starttime:
              firstPlay || item.type === Playback.QueueItemType.Episode ?
                item.starttime
              : undefined,
            type: item.format,
          },
        ];

    jwplayer.setPlaybackRate(isEpisode ? state.speed : Playback.Speed.Normal);

    jwplayer.load(playerPlaylist);

    SafeInterval.clear(LIVE_META_INTERVAL_KEY);

    if (state.station.type === Playback.StationType.Live) {
      SafeInterval.set(
        LIVE_META_INTERVAL_KEY,
        () =>
          setCurrentTrackMeta({
            streamId: Number(item.id),
            stationMeta: item.meta,
          }),
        5000,
      );
      doNotQueryAmp = false;
    } else {
      SafeInterval.clear(LIVE_META_INTERVAL_KEY);
      doNotQueryAmp = true;
    }

    firstPlay = false;
  }

  function onSeeked() {
    jwplayer.pause();
    jwplayer.setMute(false);
    jwplayer.off('seeked', onSeeked);
  }

  async function seek(position: number) {
    const status = jwplayer.getState();

    if (status === Playback.Status.Idle || status === Playback.Status.Paused) {
      jwplayer.on('seeked', onSeeked);
      jwplayer.setMute(true);
    }

    jwplayer.seek(position);
  }

  const subscription = createEmitter<
    CreateEmitter.Subscription<Playback.Player<Station>>
  >({
    fastForward: seek,

    async initialize() {
      if (isUndefined(globalThis?.window)) {
        return;
      }

      try {
        await loadScript(`https://cdn.jwplayer.com/libraries/NEkeLh0c.js`, {
          async: true,
          id: 'jw-player-script',
          replace: true,
          target: globalThis.document.head,
        });
      } catch {
        player.setError(
          PlayerError.new({
            code: PlayerErrorCode.CriticalError,
          }),
        );
      }

      const playerElement =
        globalThis.window?.document.querySelector<HTMLDivElement>('#jw-player');

      const element =
        playerElement ?? globalThis.window?.document.createElement('div');

      const wrapperElement =
        globalThis.window?.document.querySelector<HTMLDivElement>(
          '#iheart-player-container',
        );

      const wrapper =
        wrapperElement ?? globalThis.window?.document.createElement('div');

      const adEnvironment = player.getAds()?.get('env');

      const moatPartnerId = 'iheartmediajwplayer830534806428';

      if (!playerElement) {
        element.id = 'jw-player';
      }
      if (!wrapperElement) {
        wrapper.id = 'iheart-player-container';
        wrapper.append(element);
        document.body.append(wrapper);
      }

      jwplayer = window.jwplayer(element).setup({
        advertising: {
          client: 'googima',
        },
        autostart: false,
        controls: false,
        file: 'https://www.iheart.com/static/assets/blank.mp4',
        responsive: true,
        width: '100%',
        aspectratio: '16:9',
      });

      const setStatus = debounce(
        (status: Playback.Status) => player.setStatus(status),
        100,
      );
      const setAdsStatus = (status: Playback.AdPlayerStatus) =>
        player.getAds().set('status', status);

      const { jwPlayerEventHandlers, visibilitychangeHandler } =
        createPlayerEventsHandlers({
          amp,
          player,
          jwPlayer: jwplayer,
          logger,
          options: {
            moatPartnerId,
            adEnvironment,
            methods: {
              load,
              setCurrentTrackMeta,
              setStatus,
              setAdsStatus,
              isAdBreak,
            },
          },
          SafeInterval,
        });

      jwplayer.on('adBreakEnd', jwPlayerEventHandlers.adBreakEnd);
      jwplayer.on('adClick', jwPlayerEventHandlers.adClick);
      jwplayer.on('adError', jwPlayerEventHandlers.adError);
      jwplayer.on('adPlay', jwPlayerEventHandlers.adPlay);
      jwplayer.on('adRequest', jwPlayerEventHandlers.adRequest);
      jwplayer.on('adSkipped', jwPlayerEventHandlers.adSkipped);
      jwplayer.on('adStarted', jwPlayerEventHandlers.adStarted);
      jwplayer.on('adTime', jwPlayerEventHandlers.adTime);
      jwplayer.on(
        'autostartNotAllowed',
        jwPlayerEventHandlers.autostartNotAllowed,
      );
      jwplayer.on('beforeComplete', jwPlayerEventHandlers.beforeComplete);
      jwplayer.on('beforePlay', jwPlayerEventHandlers.beforePlay);
      jwplayer.on('buffer', jwPlayerEventHandlers.buffer);
      jwplayer.on('error', jwPlayerEventHandlers.error);
      jwplayer.on('idle', jwPlayerEventHandlers.idle);
      jwplayer.on('metadataCueParsed', jwPlayerEventHandlers.metadataCueParsed);
      jwplayer.on('pause', jwPlayerEventHandlers.pause);
      jwplayer.on('play', jwPlayerEventHandlers.play);
      jwplayer.on('playAttemptFailed', jwPlayerEventHandlers.playAttemptFailed);
      jwplayer.on('ready', jwPlayerEventHandlers.ready);
      jwplayer.on('setupError', jwPlayerEventHandlers.setupError);
      jwplayer.on('time', jwPlayerEventHandlers.time);

      globalThis.window.document.addEventListener(
        'visibilitychange',
        visibilitychangeHandler,
      );
    },

    load,

    loadAdXml(_, { xmlDoc }) {
      // Take the XML Document and serialize back into a string, but just from `<VAST>` tag down
      jwplayer.loadAdXml(
        new XMLSerializer().serializeToString(xmlDoc.documentElement),
      );
    },

    next(state) {
      if (isNull(state)) {
        return;
      }

      load(state);

      jwplayer.play();
    },

    pause() {
      jwplayer.pause();
    },

    pauseAd(status) {
      jwplayer.pauseAd(status);
    },

    play(status) {
      switch (status) {
        case Playback.Status.Idle: {
          jwplayer.stop();
          break;
        }
        case Playback.Status.Paused: {
          jwplayer.pause();
          break;
        }
        default: {
          jwplayer.play();
          break;
        }
      }
    },

    playAd(tag) {
      jwplayer.playAd(tag);
    },

    previous(state) {
      if (isNull(state)) {
        return;
      }

      load(state);

      jwplayer.play();
    },

    rewind: seek,

    seek,

    setMute(muted) {
      jwplayer.setMute(muted);
    },

    setSpeed(speed) {
      jwplayer.setPlaybackRate(speed);
    },

    setVolume(volume) {
      jwplayer.setMute(volume === 0);
      jwplayer.setVolume(volume);
    },

    stop() {
      const { queue, index, station } = player.getState().deserialize();
      const stationMeta = queue[index].meta;
      if (station?.type === Playback.StationType.Live) {
        SafeInterval.set(
          LIVE_META_INTERVAL_KEY,
          () =>
            setCurrentTrackMeta({
              streamId: Number(station?.id),
              stationMeta,
            }),
          5000,
        );
        doNotQueryAmp = false;
      }
      jwplayer.stop();
    },
  });

  return subscription;
}
