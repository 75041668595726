import { initContract } from '@ts-rest/core';

import { HttpMethods } from '../../../httpUtils/constants.js';
import type { V3 } from '../../../types/poweramp.js';

const c = initContract();

export const searchContract = c.router(
  {
    getSearchCombined: {
      method: HttpMethods.Get,
      path: '/combined',
      query: c.type<V3.SearchCombined.RequestQuery>(),
      responses: {
        200: c.type<V3.SearchCombined.ResponseBody>(),
      },
    },
  },
  { pathPrefix: '/search' },
);
