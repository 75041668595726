import type * as Analytics from '@iheartradio/web.analytics';

import * as Playback from './player:types.js';

export enum PlaylistTypes {
  Default = 'default',
  Generated = 'generated',
  New4U = 'new4u',
  Personalized = 'personalized',
  User = 'user',
}

type playlistAnalyticsType = {
  isCurated: boolean;
  profileId: string | undefined;
  playlistUserId: string;
  playlistId: string;
  playlistName: string;
  playlistType: string;
  userType: string | undefined;
  isAnonymous: boolean;
};

export function getAnalyticsStationData(
  station: Playback.Station,
  meta: Playback.QueueItem['meta'],
  user: Analytics.Analytics.GlobalData['user'],
) {
  switch (station?.type) {
    case Playback.StationType.Artist: {
      return {
        stationId: `${Playback.StationType.Artist}|${station.id}`,
        stationName: meta?.artistName,
      };
    }
    case Playback.StationType.Live: {
      return {
        stationId: `${Playback.StationType.Live}|${meta?.id}`,
        stationName: meta?.name,
      };
    }
    case Playback.StationType.PlaylistRadio:
    case Playback.StationType.Playlist: {
      const [userId, id] = String(station.id).split('::');

      return {
        ...getplaylistAnalyticsData({
          isCurated: meta?.isCurated,
          profileId: user?.profileId,
          playlistUserId: userId,
          playlistId: id,
          playlistName: meta?.title ?? '',
          playlistType: meta?.playlistType,
          userType: user?.subscriptionTier,
          isAnonymous: user?.registration?.type === 'ANONYMOUS',
        }),
      };
    }

    case Playback.StationType.Album: {
      return {
        stationId: `artist|${meta?.artistId}`,
        stationName: meta?.artistName,
        stationSubid: `${Playback.StationType.Album}|${meta?.albumId}`,
        stationSubname: meta?.albumName,
      };
    }
    case Playback.StationType.TopSongs: {
      return {
        stationId: `artist|${meta?.artistId}`,
        stationName: 'artist',
        stationSubid: `song|${station.id}`,
        stationSubname: meta?.title,
      };
    }
    case Playback.StationType.Podcast: {
      return {
        stationId: `${Playback.StationType.Podcast}|${meta?.podcastId}`,
        stationName: meta?.subtitle,
        stationSubid: `episode|${meta?.id}`,
        stationSubname: meta?.description,
      };
    }
    case Playback.StationType.Favorites: {
      return {
        stationId: `my_favorites_radio|${station.id}`,
        stationName: meta?.subtitle,
      };
    }
    default: {
      return {
        stationId: `${station?.type}|${station?.id}`,
        stationName: meta?.title,
      };
    }
  }
}

export function getplaylistAnalyticsData({
  isCurated,
  profileId,
  playlistUserId,
  playlistId,
  playlistName,
  playlistType,
  userType,
  isAnonymous,
}: playlistAnalyticsType) {
  if (isCurated) {
    return isAnonymous || userType === 'NONE' ?
        {
          stationId: `playlist_radio|${playlistUserId}::${playlistId}`,
          stationName: playlistName,
        }
      : {
          stationId: `curated_playlist|${playlistUserId}::${playlistId}`,
          stationName: playlistName,
        };
  }
  if (playlistType === PlaylistTypes.New4U) {
    return {
      stationId: `new_for_you_playlist|${profileId}`,
      stationName: playlistName,
    };
  }
  if (profileId === playlistUserId && playlistType !== PlaylistTypes.Default) {
    return {
      stationId: `user_playlist|${playlistUserId}::${playlistId}`,
      stationName: playlistName,
    };
  }

  if (profileId !== playlistUserId) {
    return {
      stationId: `shared_user_playlist|${playlistUserId}::${playlistId}`,
      stationName: playlistName,
    };
  }

  return {
    stationId: `my_playlist|${playlistUserId}::${playlistId}`,
    stationName: 'my_playlist',
  };
}

export function getAnalyticsData({
  analyticsState,
  followed,
  metadata,
  station,
  user,
}: {
  analyticsState: {
    streamSessionId: string;
    streamInitTime: number;
    playbackStartTime: number;
    startPosition: number;
  };
  followed: boolean;
  metadata: Playback.QueueItem['meta'];
  station: Playback.Station;
  user: Analytics.Analytics.GlobalData['user'];
}) {
  const stationData =
    station && metadata && getAnalyticsStationData(station, metadata, user);

  return stationData ?
      {
        station: {
          asset: {
            id: stationData.stationId,
            name: stationData.stationName,
            ...(stationData.stationSubid && {
              subid: stationData.stationSubid?.toString(),
              subname: stationData.stationSubname,
            }),
          },
          hadPreroll: 'preroll' in station && station.preroll ? true : false,
          isSaved: followed,
          playedFrom: station?.context ?? 0,
          playbackStartTime: analyticsState.playbackStartTime,
          sessionId: analyticsState.streamSessionId,
          streamInitTime: analyticsState.streamInitTime,
          startPosition: analyticsState.startPosition,
        },
      }
    : null;
}
