import { HTTPError } from '@iheartradio/web.api';
import type { Amp } from '@iheartradio/web.api/amp';
import { isObject, isUndefined } from '@iheartradio/web.utilities';
import { prop } from 'remeda';

import { PlayerError, PlayerErrorCode } from './player:error.js';
import type { Playback } from './player:types.js';

export type PlaybackStation = (typeof Amp.StationEnum)[Extract<
  keyof typeof Amp.StationEnum,
  'RADIO' | 'PODCAST' | 'COLLECTION'
>];

export async function fetchPlaybackStreams({
  api,
  playedFrom,
  stationType,
  contentIds = [],
  initial = true,
  seed,
  seedType,
  stationId,
}: {
  api: Playback.Api;
  playedFrom: number;
  stationType: PlaybackStation;
  contentIds?: number[];
  initial?: boolean;
  seed?: number;
  seedType?: 'ARTIST2START' | 'SONG2START';
  stationId?: string | number;
}) {
  if (
    isUndefined(stationId) ||
    (stationType === 'PODCAST' && contentIds.length === 0)
  ) {
    throw PlayerError.new({ code: PlayerErrorCode.InternalPlayerError });
  }

  try {
    const { items, ageLimit } = await api.api.v2.playback
      .postStreams({
        body: {
          contentIds,
          playedFrom,
          hostName: api.hostName,
          stationId: String(stationId),
          stationType,
          startStream:
            initial && seedType && seed ?
              { contentId: seed, reason: seedType }
            : undefined,
        },
      })
      .then(prop('body'));

    return { items, ageLimit };
  } catch (error: unknown) {
    if (error instanceof HTTPError) {
      const responseErrors = await error.getResponseErrors();
      const data = {
        requestPayload: await error.getRequestPayload(),
        requestUrl: await error.getRequestUrl(),
        responseErrors,
      };
      const noMoreSongs = responseErrors.some(
        (value: unknown) =>
          isObject(value) && 'code' in value && value.code === 617,
      );

      if (noMoreSongs) {
        throw PlayerError.new({
          code: PlayerErrorCode.ApiNoMoreSongs,
          data,
        });
      } else {
        throw PlayerError.new({
          code: PlayerErrorCode.ApiError,
          data,
        });
      }
    } else if (error instanceof Error) {
      throw PlayerError.new({
        code: PlayerErrorCode.Generic,
        data: {
          stack: error.stack,
          cause: error.cause,
          message: error.message,
        },
      });
    } else {
      throw PlayerError.new({
        code: PlayerErrorCode.Generic,
      });
    }
  }
}
