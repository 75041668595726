import { initContract } from '@ts-rest/core';
import type { SetOptional } from 'type-fest';
import { z } from 'zod';

import { ContentType, HttpMethods } from '../../../httpUtils/constants.js';
import type {
  UserProfileResponse,
  UserProfileRestValue,
  V1,
} from '../../../types/amp.js';
import type { FixErrorNaming } from '../../../types/extra.js';
import { implement } from '../../implement.js';
import { numberIdSchema } from '../../schemas/common.js';

const c = initContract();

export type UpdateProfileResponse =
  FixErrorNaming<V1.UpdateProfile.ResponseBody>;

export const profileContract = c.router(
  {
    getProfile: {
      method: HttpMethods.Get,
      path: '/:ownerProfileId/getProfile',
      description:
        "Returns user's profile. You can have this return the users Facebook friends as well. It also has the option of returning friends most recent plays.",
      pathParams: implement<V1.GetUserProfile.RequestParams>().from({
        ownerProfileId: numberIdSchema,
      }),
      query: implement<V1.GetUserProfile.RequestQuery>().from({
        /**
         * If `true`, will return any favorites. If `false`, returns none.
         * @default false
         */
        includeFavorites: z.boolean().optional(),
        /** @default false */
        includeFriends: z.boolean().optional(),
        /** @default false */
        includeFriendsPlays: z.boolean().optional(),
        /** @default false */
        includePreferences: z.boolean().optional(),
        /** @default false */
        includeSubscriptions: z.boolean().optional(),
        /**
         * Logged in user's `profileId`
         * @format int64
         */
        profileId: numberIdSchema,
        /** Logged in user's `sessionId` */
        sessionId: z.string().optional(),
        /** @default true */
        usePeriodDelimiterInPrefKeys: z.boolean().optional(),
      }),
      summary: "Get a user's profile",
      responses: {
        200: c.type<UserProfileResponse & UserProfileRestValue>(),
      },
    },

    postUpdateProfile: {
      contentType: ContentType.FormUrlEncoded,
      method: HttpMethods.Post,
      path: '/updateProfile',
      body: c.type<V1.UpdateProfile.RequestBody>(),
      summary: "Update a user's profile",
      responses: {
        200: c.type<SetOptional<V1.UpdateProfile.ResponseBody, 'success'>>(),
      },
    },
  },
  {
    pathPrefix: '/profile',
  },
);
