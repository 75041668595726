import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { HttpMethods } from '../../../httpUtils/constants.js';
import type { V3 } from '../../../types/poweramp.js';
import { implement } from '../../implement.js';
import { numberIdSchema } from '../../schemas/common.js';

const c = initContract();

export type GetStationMetaResponseBody = V3.GetStation.ResponseBody & {
  ads: {
    audio_ad_provider: 'ad-providers/triton' | 'ad-providers/adswizz' | null;
    enable_triton_token: boolean;
    provider_id: number;
  };
  adswizz: {
    publisher_id: string;
    enableAdswizzTargeting: boolean;
    adswizzHost: string;
  };
  adswizz_zones?: {
    'audio-fill-zone'?: string;
    'audio-exchange-zone'?: string;
    'audio-zone'?: string;
    'optimized-audio-fill-zone'?: string;
    'display-zone'?: string;
  };
  band: string;
  callLetterAlias: string | null;
  callLetterRoyalty: string | null;
  created: number;
  esid: string | null;
  email: string | null;
  fcc_facility_id: number | null;
  feeds: {
    enableTritonTracking: boolean;
    feed: string;
    childOriented: boolean | null;
  };
  format: string;
  freq_clean: string;
  gracenote_enabled: boolean;
  isActive: boolean;
  name_clean: string;
  phone?: string;
  raw_id: string;
  rds: string;
  rds_pi_code: string;
  recentlyPlayedEnabled: boolean;
  siri_logo: string | null;
  sms?: string;
  social: {
    facebook?: string | null;
    twitter?: string | null;
    instagram?: string | null;
    google?: string | null;
    snapchat?: string | null;
    tiktok?: string | null;
    youtube?: string | null;
    threads?: string | null;
  };
  streamingPlatform: string;
  streams: {
    hls_stream: string | null;
    secure_hls_stream: string | null;
    pls_stream: string | null;
    secure_pls_stream: string | null;
    abacast_stream: string | null;
    shoutcast_stream: string | null;
    secure_shoutcast_stream: string | null;
    pivot_hls_stream: string | null;
    dash_stream: string | null;
    secure_dash_stream: string | null;
    secure_mp3_pls_stream: string | null;
  };
  website: string;
};

export type StreamTrackHistory =
  V3.GetStreamTrackHistory.ResponseBody['data'][number];

export const livemetaContract = c.router(
  {
    getCurrentTrackMeta: {
      method: HttpMethods.Get,
      path: '/stream/:streamId/currentTrackMeta',
      pathParams: implement<V3.GetCurrentTrack.RequestParams>().from({
        streamId: numberIdSchema,
      }),
      query: implement<V3.GetCurrentTrack.RequestQuery>().from({
        /**
         * return in-stream metadata (if available) for a station even if third party listening is disabled.
         * @default false
         */
        defaultMetadata: z.boolean().optional(),
      }),
      responses: {
        200: c.type<V3.GetCurrentTrack.ResponseBody>(),
        204: c.type<never>(),
      },
    },

    getStationMeta: {
      method: HttpMethods.Get,
      path: '/stream/:stationId/station-meta',
      pathParams: implement<V3.GetStation.RequestParams>().from({
        stationId: numberIdSchema,
      }),
      responses: {
        200: c.type<GetStationMetaResponseBody>(),
      },
    },

    getStreamTrackHistory: {
      method: HttpMethods.Get,
      path: '/stream/:streamId/trackHistory',
      pathParams: implement<V3.GetStreamTrackHistory.RequestParams>().from({
        streamId: numberIdSchema,
      }),
      query: c.type<V3.GetStreamTrackHistory.RequestQuery>(),
      responses: {
        200: c.type<V3.GetStreamTrackHistory.ResponseBody>(),
      },
    },

    getStreamTopArtists: {
      method: HttpMethods.Get,
      path: '/stream/:streamId/topArtists',
      pathParams: implement<V3.GetStreamTopArtists.RequestParams>().from({
        streamId: numberIdSchema,
      }),
      query: c.type<V3.GetStreamTopArtists.RequestQuery>(),
      responses: {
        200: c.type<V3.GetStreamTopArtists.ResponseBody>(),
      },
    },
  },
  {
    pathPrefix: '/live-meta',
  },
);
