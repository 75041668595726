export function arrayCompare(a: Array<unknown>, b: Array<unknown>) {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  let equal = true;
  for (const [i, element] of a.entries()) {
    if (element != b[i]) {
      equal = false;
      break;
    }
  }
  return equal;
}
