import { HTTPError } from '@iheartradio/web.api';
import { type Poweramp, Amp } from '@iheartradio/web.api/amp';
import {
  isBlank,
  isNil,
  isNotBlank,
  isNull,
  isUndefined,
  throttle,
} from '@iheartradio/web.utilities';
import {
  type CreateEmitter,
  createEmitter,
} from '@iheartradio/web.utilities/create-emitter';
import { createWebStorage } from '@iheartradio/web.utilities/create-storage';
import type { Merge } from 'type-fest';

import { PlayerError, PlayerErrorCode } from './player:error.js';
import * as Playback from './player:types.js';
import { fetchPlaybackStreams } from './utility:streams.js';
import {
  buildCustomPreRollUrl,
  getCustomInStreamAdUrl,
  refreshPrerollUrl,
} from './utility:targeting.js';

export type PodcastStation = Merge<
  Playback.Station,
  {
    id: number;
    seed?: number;
    started?: number;
    timestamp?: Playback.Time['position'];
    type: Playback.StationType.Podcast;
  }
>;

function isEpisodeCompleted(time: Playback.Time) {
  return Math.floor(time.position) >= Math.floor(time.duration);
}

export const updateResolverEpisodeProgress = throttle(
  (
    {
      api,
      state,
    }: { api: Playback.Api; state: Playback.PlayerState<PodcastStation> },
    time: Playback.Time,
  ): boolean => {
    const { index, queue, station } = state;

    const episodeId = Number(queue[index].id);
    const podcastId = Number(station?.id);

    const completed = isEpisodeCompleted(time);
    api.api.v3.podcast.updateEpisodeProgress({
      params: { episodeId, podcastId },
      body: {
        completed,
        secondsPlayed: Math.floor(time.position),
      },
      throwOnErrorStatus: false,
    });

    return completed;
  },
  10_000,
);

type Podcast = Poweramp.ComIheartPowerampPodcastDomainFollowedPodcast & {
  adTargeting?: { providerId: number };
};

export function createPodcastResolver(): CreateEmitter.Emitter<
  Playback.Resolver<PodcastStation>
> {
  const podcastState = createWebStorage<{
    pageKey: string | undefined;
    podcast: Podcast | undefined;
    triton?: {
      [k: number]: {
        token: string;
        expiration: number;
      };
    };
  }>({
    seed: {
      pageKey: undefined,
      podcast: undefined,
      triton: undefined,
    },
    prefix: `player:resolver:podcast:state.`,
    type: 'session',
  });

  async function fetchPodcast({
    api,
    station,
  }: {
    api: Playback.Api;
    station: PodcastStation;
  }): Promise<Podcast> {
    const { body: podcast } = await api.api.v3.podcast.getPodcast({
      params: { id: station.id },
    });

    podcastState.set('podcast', podcast);

    return podcast;
  }

  async function fetchPodcastQueue({
    api,
    initial,
    station,
    isPodcastTritonTokenEnabled,
  }: {
    api: Playback.Api;
    initial?: boolean;
    station: PodcastStation;
    isPodcastTritonTokenEnabled: boolean;
  }): Promise<{ queue: Playback.Queue; childOriented: boolean }> {
    try {
      const episodes: Poweramp.ComIheartPowerampPodcastApiSimpleEpisode[] = [];

      if (initial && station.seed) {
        const { body: response } = await api.api.v3.podcast.getEpisode({
          params: { id: station.seed },
        });

        response.episode.completed =
          response.episode.completed ??
          isEpisodeCompleted({
            duration: response.episode.duration,
            position: response.episode.secondsPlayed ?? 0,
          });
        episodes.push(response.episode);
        podcastState.set('pageKey', response.pageKey);
      } else {
        const { body: response } = await api.api.v3.podcast.getPodcastEpisodes({
          params: { id: station.id },
          query: {
            limit: initial ? 2 : 1,
            pageKey: podcastState.get('pageKey'),
            sortBy:
              podcastState.get('podcast')?.showType === 'serial' ?
                'startDate-asc'
              : 'startDate-desc',
          },
        });

        // return empty queue and have the player stop.
        if (isBlank(response.data)) {
          return { queue: [], childOriented: false };
        }

        for (const episode of response.data) {
          episodes.push(episode);
        }

        podcastState.set('pageKey', response.links.next);
      }

      let childOriented = false;

      return {
        queue: await episodes
          .reduce(
            async (accumulator, episode) => {
              return {
                ...(await accumulator),
                [Symbol.for(String(episode.id))]: {
                  id: episode.id,
                  transcriptionAvailable:
                    episode.transcriptionAvailable ?? false,
                  secondsPlayed:
                    episode.completed ? 0 : (episode.secondsPlayed ?? 0), // If full episode has been played then on clicking play button again it should resatrt the episode.
                  duration: episode.duration,
                },
              };
            },
            Promise.resolve(
              {} as Record<
                symbol,
                {
                  id: number;
                  transcriptionAvailable: boolean;
                  secondsPlayed: number;
                  duration: number;
                }
              >,
            ),
          )
          .then(async episodeItems => {
            const { items, ageLimit } = await fetchPlaybackStreams({
              api,
              contentIds: Object.getOwnPropertySymbols(episodeItems).reduce(
                (accumulator, current) => {
                  return [...accumulator, episodeItems[current].id];
                },
                [] as number[],
              ),
              playedFrom: station.context,
              stationType: Amp.StationEnum.PODCAST,
              stationId: station.id,
            });

            if (ageLimit) {
              childOriented = true;
            }

            if (isUndefined(items) || items.length === 0) {
              throw PlayerError.new({ code: PlayerErrorCode.MissingStreams });
            }

            return items.reduce((streams, stream) => {
              if (stream.streamUrl && stream.content && stream.content.id) {
                const epiosdeItem =
                  episodeItems[Symbol.for(String(stream.content.id))];

                let streamUrl = stream.streamUrl;

                const triton = podcastState.get('triton');

                if (
                  isPodcastTritonTokenEnabled &&
                  station.providerId &&
                  triton !== undefined &&
                  triton?.[station.providerId] &&
                  triton?.[station.providerId].token
                ) {
                  const modifiedUrl = new URL(streamUrl);
                  modifiedUrl.searchParams.set(
                    'partnertok',
                    triton[station.providerId].token.toString()!,
                  );
                  streamUrl = modifiedUrl.toString();
                }

                streams.push({
                  id: stream.content?.id as number,
                  duration: epiosdeItem.duration ?? stream.content.duration,
                  meta: {
                    ...stream.content,
                    childOriented,
                    description: podcastState.get('podcast')?.title,
                    image: podcastState.get('podcast')?.imageUrl,
                    subtitle: stream.content?.title,
                    podcastId: podcastState.get('podcast')?.id,
                    podcastSlug: podcastState.get('podcast')?.slug,
                    title: undefined,
                    transcriptionAvailable:
                      epiosdeItem?.transcriptionAvailable ?? false,
                    secondsPlayed:
                      epiosdeItem?.secondsPlayed ??
                      stream.content.secondsPlayed ??
                      0,
                    duration: epiosdeItem?.duration ?? stream.content.duration,
                  },
                  reporting: stream.reportPayload,
                  starttime:
                    station.timestamp ?? epiosdeItem?.secondsPlayed ?? 0,
                  type: Playback.QueueItemType.Episode,
                  url: streamUrl,
                });
              }

              return streams;
            }, [] as Playback.Queue);
          }),
        childOriented,
      };
    } catch (error: unknown) {
      if (error instanceof HTTPError) {
        throw PlayerError.new({
          code: PlayerErrorCode.ApiError,
          data: {
            requestUrl: await error.getRequestUrl(),
            requestPayload: await error.getRequestPayload(),
            responseErrors: await error.getResponseErrors(),
          },
        });
      } else if (error instanceof Error) {
        throw PlayerError.new({
          code: PlayerErrorCode.Generic,
          data: {
            stack: error.stack,
            cause: error.cause,
            message: error.message,
          },
        });
      } else {
        throw PlayerError.new({ code: PlayerErrorCode.Generic });
      }
    }
  }

  const podcastResolver = createEmitter<Playback.Resolver<PodcastStation>>({
    async load({ api, state }, stationToLoad) {
      const station = { ...stationToLoad };

      if (isNull(station)) {
        return state;
      }
      if (!isUndefined(podcastState.get('podcast'))) {
        const { station, queue, index, time } = state;
        const item = queue[index];
        if (item.type === Playback.QueueItemType.Episode) {
          const episodeId = Number(queue[index].id);
          const podcastId = station!.id;

          await api.api.v3.podcast.updateEpisodeProgress({
            params: { episodeId, podcastId },
            body: {
              completed: isEpisodeCompleted(time),
              secondsPlayed: Math.floor(time.position),
            },
          });
        }
      }

      const podcast = await fetchPodcast({ api, station });

      const triton = podcastState.get('triton');

      const currentSeconds = Math.floor(Date.now() / 1000);

      const isPodcastTritonTokenEnabled =
        state.podcastTritonTokenEnabled ?? false;

      const tritonData =
        podcast?.adTargeting?.providerId &&
        triton?.[podcast.adTargeting.providerId];

      // if the podcastTritonTokenEnabled featured flag is disabled or we don't have already token in state for podcast providerid or it is expired then only we should make call to get triton token
      if (
        state.podcastTritonTokenEnabled &&
        podcast.adTargeting?.providerId &&
        (!tritonData || tritonData?.expiration < currentSeconds) &&
        state.lsid
      ) {
        const { body: response } =
          await api.api.v3.oauth.postGenerateTritonToken({
            body: {
              lsid: state.lsid,
              providerId: podcast.adTargeting.providerId,
            },
          });

        podcastState.set('triton', {
          ...triton,
          [podcast.adTargeting.providerId]: {
            token: response.token,
            expiration: response.expirationDate,
          },
        });
      }

      station.started = Date.now();
      station.name = podcast.title;

      station.providerId = podcast.adTargeting?.providerId;

      station.meta = {
        title: podcast.title,
        image: `${podcast.imageUrl}?ops=cover(400,400)`,
      };

      const { queue } = await fetchPodcastQueue({
        api,
        initial: true,
        station,
        isPodcastTritonTokenEnabled,
      });

      return {
        ...state,
        index: 0,
        station,
        time: {
          position: queue[0].starttime ?? 0,
          duration: queue[0].duration!,
        },
        queue,
      };
    },

    async midroll({ state, ads }) {
      const { queue, station } = state;
      const { targeting } = ads;

      if (isNull(station) || isNull(targeting)) {
        return null;
      }

      return getCustomInStreamAdUrl({
        queue,
        targeting,
        stationId: podcastState.get('podcast')?.id,
      });
    },

    async next({ api, state, time }) {
      const { index, queue, station, podcastTritonTokenEnabled } = state;
      if (isNull(station)) {
        return state;
      }

      let newQueue = [...queue];

      const item = newQueue[index];

      if (item && station) {
        const podcastId = Number(station.id);
        const episodeId = Number(item.id);

        const completed = isEpisodeCompleted(time);
        api.api.v3.podcast.updateEpisodeProgress({
          params: { episodeId, podcastId },
          body: {
            completed,
            secondsPlayed: Math.floor(time.position),
          },
        });

        newQueue[index] = Object.assign(newQueue[index], {
          starttime: completed ? 0 : time.position,
        });
      }

      let nextIndex = index + 1;

      if (
        nextIndex >= newQueue.length &&
        isNotBlank(podcastState.get('pageKey'))
      ) {
        const { queue: queueItems } = await fetchPodcastQueue({
          api,
          initial: false,
          station,
          isPodcastTritonTokenEnabled: podcastTritonTokenEnabled ?? false,
        });

        if (isBlank(queueItems)) {
          return null;
        }

        newQueue = newQueue.concat(queueItems);
      }

      // This **shouldn't** happen in practice, but it DID happen in test, which is why I'm accounting
      // for it here...
      //
      // If the `index` passed to this function is greater than the current length of the queue, the function
      // will fetch the next item in the queue ... but what if `index` is STILL larger than the queue array?
      // In that case, the nextIndex should be set to the last element in the queue array, hence...

      if (nextIndex >= newQueue.length) {
        nextIndex = newQueue.length - 1;
      }

      const newTime = {
        position: newQueue[nextIndex].starttime!,
        duration: newQueue[nextIndex].duration!,
      };

      return {
        ...state,
        index: nextIndex,
        queue: newQueue,
        time: newTime,
        station: { ...station, seed: Number(newQueue[nextIndex].id) },
      };
    },

    async pause({ api, state, time }) {
      const { index, queue, station, status } = state;

      const podcastId = Number(station?.id);
      const episodeId = Number(queue[index]?.id);

      api.api.v3.podcast.updateEpisodeProgress({
        params: { episodeId, podcastId },
        body: {
          completed: isEpisodeCompleted(time),
          secondsPlayed: Math.floor(time.position),
        },
        throwOnErrorStatus: false,
      });

      return status;
    },

    async preroll({ state, ads, api }) {
      let preroll;

      const { station } = state;
      const { dfpInstanceId, targeting } = ads;
      const podcast = podcastState.get('podcast');

      if (isNull(station) || isNil(podcast)) {
        return null;
      }

      const { body: playbackAds } = await api.api.v2.playback.postAds({
        body: {
          host: api.hostName,
          stationType: Amp.StationEnum.PODCAST,
          stationId: String(podcast.id),
          includeStreamTargeting: false,
          playedFrom: station.context,
        },
      });

      if (playbackAds.ads) {
        const ampPreroll = playbackAds.ads.find(ad => ad.preRoll);

        if (ampPreroll && dfpInstanceId && targeting) {
          preroll = buildCustomPreRollUrl({
            ampPrerollUrl: ampPreroll.url,
            iu: `/${dfpInstanceId}/ccr.ihr/ihr`,
            prerollTargeting: targeting.PreRoll,
          });
        }
      }

      if (!isUndefined(preroll)) {
        return refreshPrerollUrl(preroll, Playback.AdFormat.Custom);
      }
      return null;
    },

    async previous({ api, state, time }) {
      const { index, queue, station } = state;

      if (index === 0) {
        return null;
      }

      const newQueue = [...queue];

      const podcastId = Number(station?.id);
      const episodeId = Number(queue[index]?.id);

      const completed = isEpisodeCompleted(time);
      await api.api.v3.podcast.updateEpisodeProgress({
        params: { episodeId, podcastId },
        body: {
          completed,
          secondsPlayed: Math.floor(time.position),
        },
        throwOnErrorStatus: false,
      });

      newQueue[index] = Object.assign(newQueue[index], {
        starttime: completed ? 0 : Math.floor(time.position),
      });

      const previousIndex = index - 1;

      const newTime = {
        position: Math.floor(newQueue[previousIndex].starttime!),
        duration: newQueue[previousIndex].duration!,
      };

      return {
        ...state,
        queue: newQueue,
        index: previousIndex,
        time: newTime,
        station: { ...station, seed: Number(newQueue[previousIndex].id) },
      };
    },

    async seek({ api, state, time }, position) {
      const timeData = { duration: time.duration, position };
      updateResolverEpisodeProgress({ api, state }, timeData);

      return timeData.position;
    },

    async setMetadata({ state }) {
      const { index, queue } = state;

      return {
        type: Playback.MetadataType.Episode,
        data: queue[index].meta,
      };
    },

    async setTime({ api, state }, time) {
      const { index, queue } = state;

      const newQueue = [...queue];

      updateResolverEpisodeProgress({ api, state }, time);

      newQueue[index] = Object.assign(newQueue[index], {
        starttime: time.position,
      });

      return {
        ...state,
        queue: newQueue,
        time,
      };
    },
  });

  return podcastResolver;
}
