import { z } from 'zod';

export enum Keyboard {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  M = 'm',
  S = 's',
  Space = ' ',
  O = 'o',
}

export enum MetadataType {
  Ad = 'ad',
  Episode = 'episode',
  Gracenote = 'gracenote',
  InStream = 'in-stream',
  Station = 'station',
  Track = 'track',
}

export enum QueueItemFormat {
  AAC = 'aac',
  HLS = 'hls',
  MP3 = 'mp3',
}

export enum QueueItemType {
  Episode = 'episode',
  Stream = 'stream',
  Track = 'track',
  Event = 'event',
}

export enum Repeat {
  No = 0,
  Yes = 1,
  One = 2,
}

export enum StationType {
  Album = 'album',
  Artist = 'artist',
  Favorites = 'favorites',
  Live = 'live',
  Playlist = 'playlist',
  PlaylistRadio = 'playlist-radio',
  Podcast = 'podcast',
  TopSongs = 'top-songs',
}

export enum PreRollStations {
  Artist = 'artist',
  Favorites = 'favorites',
  Live = 'live',
  PlaylistRadio = 'playlist-radio',
  Podcast = 'podcast',
}

export enum Speed {
  Slow = 0.5,
  Normal = 1,
  Fast = 1.25,
  Faster = 1.5,
  Fastest = 2,
}

export enum Status {
  Buffering = 'buffering',
  Idle = 'idle',
  Paused = 'paused',
  Playing = 'playing',
}

export enum AudioAdProvider {
  Adswizz = 'ad-providers/adswizz',
  Triton = 'ad-providers/triton',
}

export enum AdType {
  Midroll = 'midroll',
  Preroll = 'preroll',
  Instream = 'instream',
}

export enum AdStatus {
  Request = 'request',
  Complete = 'complete',
  Error = 'error',
}

export enum AdFormat {
  Custom = 'custom',
  Live = 'live',
}

export enum AdPlayerStatus {
  Buffering = 'buffering',
  Idle = 'idle',
  Paused = 'paused',
  Playing = 'playing',
  Streaming = 'streaming',
  Done = 'done',
}

export const AdFormatSchema = z.nativeEnum(AdFormat);

export const AdTypeSchema = z.nativeEnum(AdType);

export const AdStatusSchema = z.nativeEnum(AdStatus);

export const AdPlayerStatusSchema = z.nativeEnum(AdPlayerStatus);

export const AudioAdProviderSchema = z.nativeEnum(AudioAdProvider);

export const LivePrerollAdTargetingSchema = z.object({
  ccrcontent2: z.string().optional(),
  ccrformat: z.string().optional(),
  ccrmarket: z.string().optional(),
  ccrpos: z.string().optional(),
  cust_params: z.record(z.unknown()).optional(),
  deviceType: z.string().optional(),
  env: z.string().optional(),
  iu: z.string().optional(),
  locale: z.string().optional(),
  playedfrom: z.string().optional(),
  profileId: z.coerce.string().optional(),
  rdp: z.string().optional(),
  url: z.string().optional(),
  us_privacy: z.string().optional(),
  visitNum: z.number().optional().catch(1),
});

export const CustomPrerollAdTargetingSchema = z.object({
  'aw_0_1st.ihmgenre': z.string().optional(),
  'aw_0_1st.playlistid': z.string().optional(),
  'aw_0_1st.playlisttype': z.string().optional(),
  ccrcontent3: z.string().optional(),
  cust_params: z.record(z.unknown()).optional(),
  deviceType: z.string().optional(),
  env: z.string().optional(),
  iu: z.string().optional(),
  locale: z.string().optional(),
  playedFrom: z.string().optional(),
  profileId: z.coerce.string().optional(),
  rdp: z.string().optional(),
  seed: z.coerce.string().optional(),
  us_privacy: z.string().optional(),
  visitNum: z.number().optional().catch(1),
});

export const LiveInStreamAdTargetingSchema = z
  .object({
    'aw_0_1st.playerId': z.string().optional(),
    'aw_0_1st.skey': z.string().optional(),
    callLetters: z.string().optional(),
    clientType: z.string().optional(),
    'device-language': z.string().optional(),
    'device-osv': z.string().optional(),
    devicename: z.string().optional(),
    dist: z.string().optional(),
    dnt: z
      .union([z.literal(0).or(z.literal('0')), z.literal(1).or(z.literal('1'))])
      .pipe(z.coerce.string())
      .optional(),
    host: z.string().optional(),
    'iheart-encr': z.string().optional(),
    locale: z.string().optional(),
    modTime: z.number().optional(),
    partnertok: z.string().optional(),
    profileid: z.coerce.string().optional(),
    'site-url': z.string().url().optional(),
    sessionstart: z.boolean().optional(),
    streamid: z.coerce.string().optional(),
    terminalid: z.number().optional(),
    territory: z.string().optional(),
    ua: z.string().optional(),
    us_privacy: z.string().optional(),
    zip: z.coerce.string().optional(),
    podcastTritonTokenEnabled: z.boolean().optional(),
  })
  .passthrough();

export const ExtraTargetingSchema = z.object({
  partnerTokens: z.object({
    coppa: z.string(),
    nonCoppa: z.string(),
  }),
});

export const LiveTargetingSchema = z.object({
  InStream: LiveInStreamAdTargetingSchema,
  PreRoll: LivePrerollAdTargetingSchema,
  Extra: ExtraTargetingSchema,
});

export const CustomInStreamAdTargetingSchema =
  LiveInStreamAdTargetingSchema.merge(
    z.object({
      ihmgenre: z.string().optional(),
      playlistid: z.string().optional(),
      playlisttype: z.string().optional(),
      sessionid: z.string().optional(),
      tags: z.string().optional(),
      streamid: z.string().optional(),
    }),
  );

export const CustomTargetingSchema = z.object({
  InStream: CustomInStreamAdTargetingSchema,
  PreRoll: CustomPrerollAdTargetingSchema,
  Extra: ExtraTargetingSchema,
});

export const AdTagSchema = z.string().url();

export enum CompanionResourceType {
  Static = 'Static',
  HTML = 'HTML',
  IFrame = 'IFrame',
}
export const CompanionResourceTypeSchema = z.nativeEnum(CompanionResourceType);
export type CompanionResourceTypeSchema = z.infer<
  typeof CompanionResourceTypeSchema
>;

export interface ICompanionSchema {
  apiFramework?: string;
  content?: string;
  size?: {
    width: number;
    height: number;
  };
  fluidSize?: boolean;
  resourceType?: CompanionResourceTypeSchema;
  resourceValue?: string;
  contentType?: string | null;
  sequenceNumber?: number;
  mainAdSequenceNumber?: number;
  adSlotId?: string | number | null;
  backupCompanions?: ICompanionSchema[];
  height?: number;
  width?: number;
  clickThroughUrl?: string | null;
}

export const CompanionSchema: z.ZodType<ICompanionSchema> = z.lazy(() =>
  z.object({
    apiFramework: z.string().optional(),
    content: z.string().optional(),
    size: z
      .object({
        width: z.number().nonnegative(),
        height: z.number().nonnegative(),
      })
      .optional(),
    fluidSize: z.boolean().optional(),
    resourceType: CompanionResourceTypeSchema.optional(),
    contentType: z.string().nullable().optional(),
    sequenceNumber: z.number().nonnegative().optional(),
    mainAdSequenceNumber: z.number().optional(),
    adSlotId: z.number().or(z.string()).nullable().optional(),
    backupCompanions: z.array(CompanionSchema),
    height: z.number().nonnegative().optional(),
    width: z.number().nonnegative().optional(),
    clickThroughUrl: z.string().nullable().optional(),
  }),
);

export const CompanionsSchema = z.array(CompanionSchema).nullable();

export type Companion = z.infer<typeof CompanionSchema>;

export const AdPayloadSchema = z.object({
  adIndex: z.number().default(1).optional(),
  companions: CompanionsSchema,
  format: AdFormatSchema,
  tag: AdTagSchema,
  totalAds: z.number().default(1).optional(),
  type: AdTypeSchema,
});

export type Ad = z.infer<typeof AdSchema>;

export type Ads = z.infer<typeof AdsSchema>;

export type AdTag = z.infer<typeof AdTagSchema>;

export type AdPayload = z.infer<typeof AdPayloadSchema>;

export type LiveInStreamAdTargeting = z.infer<
  typeof LiveInStreamAdTargetingSchema
>;

export type CustomInStreamAdTargeting = z.infer<
  typeof CustomInStreamAdTargetingSchema
>;

export type LivePrerollAdTargeting = z.infer<
  typeof LivePrerollAdTargetingSchema
>;

export type CustomPrerollAdTargeting = z.infer<
  typeof CustomPrerollAdTargetingSchema
>;

export type PrerollAdTargeting =
  | LivePrerollAdTargeting
  | CustomPrerollAdTargeting;

export type LiveTargeting = z.infer<typeof LiveTargetingSchema>;

export type CustomTargeting = z.infer<typeof CustomTargetingSchema>;

export type Targeting = LiveTargeting | CustomTargeting;

export const KeyboardTypeSchema = z.nativeEnum(Keyboard);

export const QueueItemFormatSchema = z.nativeEnum(QueueItemFormat);

export const QueueItemTypeSchema = z.nativeEnum(QueueItemType);

export const BaseMetadataSchema = z.object({
  description: z.string().optional(),
  image: z.string().url().optional(),
  subtitle: z.string().optional(),
  title: z.string().optional(),
});

export const QueueItemMetaSchema = z.intersection(
  z.record(z.string(), z.any()),
  BaseMetadataSchema,
);

export const QueueItemSchema = z.object({
  format: QueueItemFormatSchema.optional(),
  id: z.union([z.number().nonnegative(), z.string()]),
  meta: QueueItemMetaSchema,
  reporting: z.string().optional(),
  starttime: z.number().nonnegative().optional(),
  duration: z.number().nonnegative().optional(),
  type: QueueItemTypeSchema,
  url: z.string().url(),
});

export const QueueSchema = z.array(QueueItemSchema);

export const MetadataTypeSchema = z.nativeEnum(MetadataType);

export const MetadataSchema = z
  .discriminatedUnion('type', [
    z.object({
      type: z.literal(MetadataType.Ad),
      data: QueueItemMetaSchema,
    }),
    z.object({
      type: z.literal(MetadataType.Episode),
      data: QueueItemMetaSchema,
    }),
    z.object({
      type: z.literal(MetadataType.Gracenote),
      data: QueueItemMetaSchema,
    }),
    z.object({
      type: z.literal(MetadataType.InStream),
      data: QueueItemMetaSchema,
    }),
    z.object({
      type: z.literal(MetadataType.Station),
      data: QueueItemMetaSchema,
    }),
    z.object({
      type: z.literal(MetadataType.Track),
      data: QueueItemMetaSchema,
    }),
  ])
  .nullable();

export const RepeatSchema = z.nativeEnum(Repeat);

export const SpeedSchema = z.nativeEnum(Speed);

export const SeekValue = z.number().nonnegative();

export const StatusSchema = z.nativeEnum(Status);

export const TimeSchema = z
  .object({
    duration: SeekValue,
    position: SeekValue,
  })
  .strict();

export const StationTypeSchema = z.nativeEnum(StationType);

export const PreRollStationSchema = z.nativeEnum(PreRollStations);

export const StationSchema = z
  .object({
    context: z.number().nonnegative(),
    id: z.union([z.number().nonnegative(), z.string()]),
    seed: z.union([z.number().nonnegative(), z.string()]).optional(),
    type: StationTypeSchema,
    name: z.string().nullable().optional(),
    meta: BaseMetadataSchema.optional(),
    targeting: LiveTargetingSchema.or(CustomTargetingSchema),
    providerId: z.number().nullable().optional(),
  })
  .nullable();

export const AdSchema = z.object({
  format: AdFormatSchema,
  station: StationSchema,
  tag: AdTagSchema,
  timestamp: z.number().nonnegative(),
  type: AdTypeSchema,
  status: AdStatusSchema,
});

export const AdsSchema = z.object({
  current: AdPayloadSchema.optional(),
  companionClickThroughs: z.array(z.string().or(z.null())),
  dfpInstanceId: z.number().nullable().optional(),
  enabled: z.boolean(),
  env: z.string().nullable().optional(),
  errors: z.array(z.instanceof(Error)),
  history: z.array(AdSchema),
  sessionid: z.string().uuid().nullable(),
  sessionstart: z.boolean().nullable(),
  status: AdPlayerStatusSchema,
  subscriptionType: z.string().optional().default('free'),
  targeting: LiveTargetingSchema.or(CustomTargetingSchema),
  type: z.union([z.literal('audio'), z.literal('video'), z.literal('unknown')]),
});

export const VolumeSchema = z.number().gte(0).lte(100);

export const HistoryItemSchema = z.object({
  station: StationSchema,
  item: QueueItemSchema,
  timestamp: z.number().nonnegative(),
});

export const HistorySchema = z.array(HistoryItemSchema);

export function PlayerStateSchema<Schema extends Station>(
  Station: z.ZodSchema<Schema>,
) {
  return z.object({
    errors: z.array(z.instanceof(Error)),
    history: HistorySchema,
    index: z.number().nonnegative(),
    metadata: MetadataSchema,
    muted: z.boolean(),
    queue: QueueSchema,
    repeat: RepeatSchema,
    shuffled: z.boolean(),
    skips: z.number().nonnegative(),
    speed: SpeedSchema,
    station: Station,
    status: StatusSchema,
    time: TimeSchema,
    volume: VolumeSchema,
    pageName: z.string().optional().nullable(),
    lsid: z.string().optional(),
    podcastTritonTokenEnabled: z.boolean().optional().nullable(),
  });
}

export type HistoryItem = z.infer<typeof HistoryItemSchema>;

export type Metadata = z.infer<typeof MetadataSchema>;

export type Queue = z.infer<typeof QueueSchema>;

export type QueueItem = z.infer<typeof QueueItemSchema>;

export type QueueItemMeta = z.infer<typeof QueueItemMetaSchema>;

export type PlayerState<T extends Station> = z.infer<
  ReturnType<typeof PlayerStateSchema<T>>
>;

export type SeekValue = z.infer<typeof SeekValue>;

export type Station = z.infer<ReturnType<typeof StationSchema.unwrap>>;

export type Time = z.infer<typeof TimeSchema>;

export type Volume = z.infer<typeof VolumeSchema>;
