import { initContract } from '@ts-rest/core';

import { HttpMethods } from '../../../httpUtils/constants.js';
import type { V3 } from '../../../types/poweramp.js';

const c = initContract();

export const privacyContract = c.router(
  {
    getPrivacySettings: {
      method: HttpMethods.Get,
      path: '/accounts/:profileId/privacySettings',
      pathParams: c.type<V3.GetPrivacySettings.RequestParams>(),
      responses: {
        200: c.type<V3.GetPrivacySettings.ResponseBody>(),
      },
    },
  },
  { pathPrefix: '/privacy' },
);
