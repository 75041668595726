import { initContract } from '@ts-rest/core';
import type { Merge, Simplify } from 'type-fest';
import { z } from 'zod';

import { ContentType, HttpMethods } from '../../../httpUtils/constants.js';
import type {
  ArtistResponse,
  ErrorEntry,
  LiveResponse,
  PlaylistStationResponse,
  StationResponse,
  V2,
} from '../../../types/amp.js';
import { implement } from '../../implement.js';
import {
  fieldSchema,
  limitSchema,
  numberIdSchema,
  offsetSchema,
  stringIdSchema,
} from '../../schemas/common.js';

type GetStationsResponse = Merge<
  V2.GetStations.ResponseBody,
  {
    hits: Merge<
      PlaylistStationResponse,
      {
        content?: (LiveResponse | StationResponse | ArtistResponse)[];
        artistName?: string;
        seedArtistId?: number;
        seedProfileId?: number;
        urls?: { image: string };
        ownerId?: string;
        playlistId?: string;
      }
    >[];
  }
>;

type GetFavoritesStationResponse = {
  duration: number;
  value: {
    name: string;
    id: string;
    variety: 'TOP_HITS' | 'MIX' | 'VARIETY';
    lastPlayedDate: number;
    registeredDate: number;
    lastModifiedDate: number;
    stationType: 'FAVORITES';
    playCount: number;
    slug: string;
    link: string;
    deviceLink: string;
    imagePath: string;
    description: string;
    seedProfileId: number;
  };
};

const c = initContract();

const stationTypeEnum = z.enum([
  'ARTIST',
  'COLLECTION',
  'FAVORITES',
  'LIVE',
  'RADIO',
  'TALK',
  'TALKSHOW',
  'TALKTHEME',
  'TRACK',
  'CLIP',
]);

const sortByEnum = z.enum([
  'LAST_MODIFIED_DATE',
  'LAST_PLAYED',
  'NAME',
  'PLAYCOUNT',
  'REGISTERED_DATE',
  'TYPE',
]);

type AddStationResponseBody = Merge<
  V2.AddStation.ResponseBody,
  { errors?: ErrorEntry[] }
>;

export const playlistsContract = c.router(
  {
    getStationsById: {
      method: HttpMethods.Get,
      path: '/:profileId/:type/:stationId',
      pathParams: implement<V2.GetStationsById.RequestParams>().from({
        profileId: numberIdSchema,
        type: stationTypeEnum,
        stationId: stringIdSchema,
      }),
      query: implement<V2.GetStationsById.RequestQuery>().from({
        // TODO: make this type aware of the response object keys
        fields: fieldSchema,
        limit: limitSchema,
        offset: offsetSchema,
        /**
         * @default "NAME"
         */
        sortBy: sortByEnum.optional(),
      }),
      responses: {
        200: c.type<V2.GetStationsById.ResponseBody>(),
      },
    },

    postAddStation: {
      method: HttpMethods.Post,
      contentType: ContentType.FormUrlEncoded,
      path: '/:profileId/:type/:contentId',
      pathParams: implement<V2.AddStation.RequestParams>().from({
        profileId: numberIdSchema,
        type: stationTypeEnum,
        contentId: stringIdSchema,
      }),
      body: c.type<
        Partial<
          Simplify<
            V2.AddStation.RequestBody & { playedFrom?: number | undefined }
          >
        >
      >(),
      responses: {
        200: c.type<AddStationResponseBody>(),
      },
    },

    getStations: {
      method: HttpMethods.Get,
      path: '/:profileId',
      pathParams: implement<V2.GetStations.RequestParams>().from({
        profileId: numberIdSchema,
      }),
      query: implement<V2.GetStations.RequestQuery>().from({
        campaignId: z.string().optional(),
        fields: z.string().optional(),
        includePersonalized: z.boolean().optional(),
        limit: limitSchema,
        offset: offsetSchema,
        sortBy: sortByEnum,
        stationTypes: stationTypeEnum
          .array()
          .transform(value => value.join(','))
          .optional(),
      }),
      headers: c.type<Partial<V2.GetStations.RequestHeaders>>(),
      responses: {
        200: c.type<GetStationsResponse>(),
      },
      metadata: {
        failOnValidationMismatch: false,
      } as const,
    },

    getFavoritesStation: {
      method: HttpMethods.Get,
      path: '/:profileId/FAVORITES/seedId/:profileId',
      pathParams: implement<
        Omit<V2.GetStationBySeedId.RequestParams, 'type' | 'seedId'>
      >().from({
        profileId: numberIdSchema,
      }),
      headers: c.type<Partial<V2.GetStationBySeedId.RequestHeaders>>(),
      responses: {
        200: c.type<GetFavoritesStationResponse>(),
      },
    },
  },
  {
    pathPrefix: '/playlists',
  },
);
