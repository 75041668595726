import { isNull, isUndefined } from '@iheartradio/web.utilities';
import {
  type CreateEmitter,
  createEmitter,
} from '@iheartradio/web.utilities/create-emitter';
import {
  type CreateStorage,
  createMemoryStorage,
} from '@iheartradio/web.utilities/create-storage';
import { isDeepEqual, isNullish, prop } from 'remeda';

import * as Playback from './player:types.js';

export function createRoyaltyReportingSubscription<
  Resolvers extends Playback.Resolvers<any>,
  Station extends Playback.Station,
>({
  api,
  resolvers,
  state,
}: {
  api: Playback.Api;
  resolvers: Resolvers;
  state: CreateStorage.Storage<Playback.PlayerState<Station>>;
}): CreateEmitter.Emitter<
  CreateEmitter.Subscription<Playback.Player<Station>>
> {
  const royaltyReportingState = createMemoryStorage<{
    elapsed: number;
    endReason: 'DONE' | 'REPORT_15' | 'SKIP' | 'START' | 'STATIONCHANGE' | null;
    fetchedSkips: boolean;
    previousPosition: number;
    report15Reported: boolean;
    startReported: boolean;
  }>({
    elapsed: 0,
    endReason: null,
    fetchedSkips: false,
    previousPosition: 0,
    report15Reported: false,
    startReported: false,
  });

  const royaltyReporting = createEmitter<
    CreateEmitter.Subscription<Playback.Player<Station>>
  >({
    async next(_, internal) {
      const { index, station, queue } = state.deserialize();

      const item = queue[index];

      if (
        internal ||
        !isNull(royaltyReportingState.get('endReason')) ||
        !royaltyReportingState.get('startReported') ||
        isNull(station) ||
        isUndefined(item) ||
        isUndefined(station) ||
        item.type !== Playback.QueueItemType.Track
      ) {
        return;
      }

      royaltyReportingState.set('endReason', 'SKIP');
      royaltyReportingState.set('startReported', false);

      const { daySkipsRemaining, hourSkipsRemaining } =
        await api.api.v3.playback
          .postReporting({
            body: {
              modes:
                (
                  state.get('shuffled') &&
                  !isUndefined(resolvers[station.type].setShuffle)
                ) ?
                  ['SHUFFLED']
                : [],
              offline: false,
              playedDate: Date.now(),
              replay: false,
              reportPayload: item.reporting,
              secondsPlayed: Math.round(royaltyReportingState.get('elapsed')),
              stationId: String(station.id),
              status: 'SKIP',
            },
          })
          .then(prop('body'));

      state.set(
        'skips',
        Math.max(0, Math.min(hourSkipsRemaining, daySkipsRemaining)),
      );
    },

    play() {
      const { history, station } = state.deserialize();

      const [{ station: previousStation, item }] = history;

      if (
        !isNull(royaltyReportingState.get('endReason')) ||
        !royaltyReportingState.get('startReported') ||
        isNullish(previousStation) ||
        isUndefined(item) ||
        isDeepEqual(previousStation, station as any) ||
        item.type !== Playback.QueueItemType.Track
      ) {
        return;
      }

      royaltyReportingState.set('endReason', 'STATIONCHANGE');
      royaltyReportingState.set('startReported', false);

      api.api.v3.playback.postReporting({
        body: {
          modes:
            (
              state.get('shuffled') &&
              !isUndefined(resolvers[previousStation.type].setShuffle)
            ) ?
              ['SHUFFLED']
            : [],
          offline: false,
          playedDate: Date.now(),
          replay: false,
          reportPayload: item.reporting,
          secondsPlayed: Math.round(royaltyReportingState.get('elapsed')),
          stationId: String(previousStation.id),
          status: 'STATIONCHANGE',
        },
      });
    },

    midroll() {
      const { index, station, queue } = state.deserialize();

      const item = queue[index];

      if (
        !isNull(royaltyReportingState.get('endReason')) ||
        !royaltyReportingState.get('startReported') ||
        isNull(station) ||
        isUndefined(station) ||
        isUndefined(item) ||
        item.type !== Playback.QueueItemType.Track
      ) {
        return;
      }

      royaltyReportingState.set('endReason', 'DONE');
      royaltyReportingState.set('startReported', false);

      api.api.v3.playback.postReporting({
        body: {
          modes:
            (
              state.get('shuffled') &&
              !isUndefined(resolvers[station.type].setShuffle)
            ) ?
              ['SHUFFLED']
            : [],
          offline: false,
          playedDate: Date.now(),
          replay: false,
          reportPayload: item.reporting,
          secondsPlayed: Math.round(royaltyReportingState.get('elapsed')),
          stationId: String(station.id),
          status: 'DONE',
        },
      });
    },

    async preroll() {
      const { index, station, queue } = state.deserialize();

      const item = queue[index];

      if (
        isNull(station) ||
        isUndefined(item) ||
        isUndefined(station) ||
        item.type === Playback.QueueItemType.Episode ||
        royaltyReportingState.get('startReported')
      ) {
        return;
      }

      royaltyReportingState.serialize(royaltyReportingState.seed);

      if (item.type === Playback.QueueItemType.Stream) {
        api.api.v3.playback.postLiveStationReporting({
          body: {
            stationId: String(station.id),
            playedFrom: station.context,
            stationType: station.type,
          },
        });

        return;
      }

      royaltyReportingState.set('startReported', true);

      const { daySkipsRemaining, hourSkipsRemaining } =
        await api.api.v3.playback
          .postReporting({
            body: {
              modes:
                (
                  state.get('shuffled') &&
                  !isUndefined(resolvers[station.type].setShuffle)
                ) ?
                  ['SHUFFLED']
                : [],
              offline: false,
              playedDate: Date.now(),
              replay: false,
              reportPayload: item.reporting,
              secondsPlayed: 0,
              stationId: String(station.id),
              status: 'START',
            },
          })
          .then(prop('body'));

      state.set(
        'skips',
        Math.max(0, Math.min(hourSkipsRemaining, daySkipsRemaining)),
      );

      royaltyReportingState.set('fetchedSkips', true);
    },

    seek(position) {
      royaltyReportingState.set('previousPosition', position);
    },

    async setTime({ time }) {
      const { index, station, queue } = state.deserialize();

      const item = queue[index];

      const elapsed =
        royaltyReportingState.get('elapsed') +
        (time.position - royaltyReportingState.get('previousPosition'));

      royaltyReportingState.set('elapsed', elapsed);
      royaltyReportingState.set('previousPosition', time.position);

      if (
        !royaltyReportingState.get('startReported') ||
        royaltyReportingState.get('report15Reported') ||
        elapsed < 15 ||
        isNull(station) ||
        isUndefined(station) ||
        isUndefined(item) ||
        item.type !== Playback.QueueItemType.Track
      ) {
        return;
      }

      royaltyReportingState.set('report15Reported', true);

      api.api.v3.playback.postReporting({
        body: {
          modes:
            (
              state.get('shuffled') &&
              !isUndefined(resolvers[station.type].setShuffle)
            ) ?
              ['SHUFFLED']
            : [],
          offline: false,
          playedDate: Date.now(),
          replay: false,
          reportPayload: item.reporting,
          secondsPlayed: Math.round(elapsed),
          stationId: String(station.id),
          status: 'REPORT_15',
        },
      });
    },
  });

  return royaltyReporting;
}
