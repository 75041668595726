// This file and package export was created so as not to taint the client bundles with
// server-only code.
import { createCookie } from '@fastly/remix-server-runtime';
import {
  TOKEN_COOKIE_NAME,
  TOKEN_COOKIE_OPTIONS,
} from '@iheartradio/web.api/amp';
import { createTypedCookie } from 'remix-utils/typed-cookie';
import { z } from 'zod';

export const ApiTokenCookieJar = createTypedCookie({
  cookie: createCookie(TOKEN_COOKIE_NAME, TOKEN_COOKIE_OPTIONS),
  schema: z
    .object({
      token: z.string(),
      expiration: z.number(),
    })
    .optional(),
});
