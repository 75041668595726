import type {
  ampContract,
  ClientInferResponseBody,
  GetTargetingResponse,
} from '@iheartradio/web.api/amp';
import {
  CCPAUserPrivacy,
  isObject,
  memoize,
  uuid,
} from '@iheartradio/web.utilities';

import { PlayerError } from './player:error.js';
import {
  type CustomPrerollAdTargeting,
  LiveInStreamAdTargetingSchema,
} from './player:schemas.js';
import * as Playback from './player:types.js';
import {
  DEFAULT_PREROLL_PARAMS,
  GAM_VAST_BASE_URL,
  TRITON_VAST_BASE_URL,
} from './utility:constants.js';
export { shouldAdPlay } from './utility:should-ad-play.js';

const buildTargetingQueryParams = (entries: [string, unknown][]) => {
  const queryParams = new URLSearchParams();
  for (const [key, value] of entries) {
    if (isObject(value)) {
      queryParams.set(
        key,
        buildTargetingQueryParams(Object.entries(value)).toString(),
      );
    } else {
      queryParams.set(key, String(value));
    }
  }

  return queryParams;
};

const refreshPrerollUniqueTargetingParameters = (
  searchParams: URLSearchParams,
) => {
  const now = Date.now().toString();

  searchParams.set('ts', now);
  searchParams.set('ord', cachebuster());

  return searchParams;
};

export type LiveInStreamAdTargetingOptions = Pick<
  ClientInferResponseBody<typeof ampContract.v3.livemeta.getStationMeta>,
  'ads' | 'adswizz' | 'callLetters' | 'feeds' | 'id'
> & { subscriptionType: string };

export const buildLiveInstreamTargeting = (
  targeting: Playback.LiveTargeting,
  options: LiveInStreamAdTargetingOptions,
) => {
  const { audio_ad_provider, enable_triton_token } = options.ads;
  const partnertok =
    options.feeds.childOriented ?
      targeting?.Extra?.partnerTokens?.coppa
    : targeting?.Extra?.partnerTokens?.nonCoppa;

  let InStream = {
    ...targeting?.InStream,
  };

  if (
    audio_ad_provider === Playback.AudioAdProvider.Triton &&
    enable_triton_token &&
    partnertok
  ) {
    InStream.partnertok = partnertok;
  } else if (audio_ad_provider === Playback.AudioAdProvider.Adswizz) {
    const stripped = LiveInStreamAdTargetingSchema.safeParse(InStream);
    if (stripped.success) {
      InStream = {
        ...stripped.data,
        'aw_0_1st.playerId': 'iHeartRadioWebPlayer',
        'aw_0_1st.skey': InStream.profileid,
      };
    }
  }

  if (options.adswizz.enableAdswizzTargeting) {
    const iheartEncrParams = new URLSearchParams();
    const listenerId = globalThis.window.com_adswizz_synchro_getListenerId?.();

    if (listenerId) {
      iheartEncrParams.set('aw_0_1st.listenerId', listenerId);
    }

    const stripped = LiveInStreamAdTargetingSchema.safeParse(InStream);
    if (stripped.success) {
      InStream = {
        ...stripped.data,
        'iheart-encr': btoa(iheartEncrParams.toString()),
      };
    }
  }

  InStream = {
    ...InStream,
    callLetters: options.callLetters,
    dist: 'iheart',
    streamid: options.id.toString(),
    ...(enable_triton_token && partnertok ? { partnertok } : {}),
    subscription_type:
      options.subscriptionType === 'PREMIUM' ?
        'all_access'
      : options.subscriptionType.toLowerCase(),
  };

  return InStream;
};

export const buildLivePreRollUrl = (
  targeting: Playback.PrerollAdTargeting,
  userOptedOut: boolean,
) => {
  try {
    if (userOptedOut) {
      targeting.rdp = '1';
      targeting.cust_params = {
        ...(targeting.cust_params as object),
        age: null,
        gender: null,
        zip: null,
      };
    }

    const targetingQueryParams = buildTargetingQueryParams(
      Object.entries(targeting),
    );

    return targetingQueryParams.toString().length > 0 ?
        new URL(
          `${GAM_VAST_BASE_URL}?${targetingQueryParams.toString()}`,
        ).toString()
      : undefined;
  } catch {
    console.error(PlayerError.Targeting.message);
  }
};

export type CustomInStreamAdTargetingOptions = {
  childOriented: boolean;
  stationId?: string;
};
const buildCustomInStreamTargeting = ({
  stationTargeting,
  ampTargeting,
  options,
}: {
  stationTargeting: Playback.CustomTargeting;
  ampTargeting?: GetTargetingResponse;
  options: CustomInStreamAdTargetingOptions;
}) => {
  const InStream = {
    ...stationTargeting.InStream,
  };

  const partnertok =
    options.childOriented ?
      stationTargeting?.Extra?.partnerTokens?.coppa
    : stationTargeting?.Extra?.partnerTokens?.nonCoppa;

  if (partnertok) {
    InStream.partnertok = partnertok;
  }

  const ihmgenre = ampTargeting?.['aw_0_1st.ihmgenre'];
  const playlistid = ampTargeting?.['aw_0_1st.playlistid'];
  const playlisttype = ampTargeting?.['aw_0_1st.playlisttype'];

  return {
    ...InStream,
    ihmgenre,
    playlistid,
    playlisttype,
    dist: 'iheart',
    ...(options.stationId ? { streamid: options.stationId } : {}),
    tags: ihmgenre,
    'break-id': uuid(),
  };
};

export const cachebuster = () => {
  const randomArray = new Uint32Array(2);
  globalThis.window.crypto.getRandomValues<Uint32Array>(randomArray);

  return randomArray.join('');
};

export const getCustomInStreamAdUrl = ({
  ampTargeting,
  paramsKey,
  queue,
  stationId,
  targeting,
}: {
  ampTargeting?: GetTargetingResponse;
  paramsKey?: string;
  queue: Playback.Queue;
  stationId?: string | number;
  targeting: Playback.Targeting;
}): Playback.AdPayload | null => {
  const streamTargeting = buildCustomInStreamTargeting({
    stationTargeting: {
      ...targeting,
      InStream: {
        ...targeting?.InStream,
        sessionstart: false,
      },
    },
    ampTargeting,
    options: {
      childOriented: queue.some(({ meta }) => !!meta.childOriented),
      ...(stationId ? { stationId: String(stationId) } : {}),
    },
  });

  const midroll = buildCustomInStreamAdUrl(streamTargeting);

  if (midroll) {
    const instreamVastUrl = new URL(midroll);

    const searchParams = new URLSearchParams(
      (() => {
        if (paramsKey && instreamVastUrl.searchParams.has(paramsKey)) {
          return instreamVastUrl.searchParams.get(paramsKey)!.toString();
        }
      })(),
    );

    refreshPrerollUniqueTargetingParameters(
      paramsKey ? searchParams : instreamVastUrl.searchParams,
    );

    if (paramsKey) {
      instreamVastUrl.searchParams.set(paramsKey, searchParams.toString());
    }

    return {
      format: Playback.AdFormat.Custom,
      tag: instreamVastUrl.toString(),
      type: Playback.AdType.Midroll,
      companions: null,
    };
  }

  return null;
};

export const refreshPrerollUrl = (
  preroll: string,
  format: Playback.AdFormat,
): Playback.AdPayload | null => {
  try {
    const prerollUrl = new URL(preroll);

    const cust_params = new URLSearchParams(
      (() => {
        const cust_params = prerollUrl.searchParams.get('cust_params');
        if (cust_params) return cust_params;
      })(),
    );

    refreshPrerollUniqueTargetingParameters(cust_params);

    prerollUrl.searchParams.set('cust_params', cust_params.toString());

    return {
      format,
      tag: prerollUrl.toString(),
      type: Playback.AdType.Preroll,
      companions: null,
    };
  } catch {
    return null;
  }
};

export const buildCustomInStreamAdUrl = (
  targeting: Playback.CustomInStreamAdTargeting,
) => {
  const { ua, us_privacy, ...rest } = targeting;

  try {
    const targetingQueryParams = buildTargetingQueryParams(
      Object.entries({
        ...rest,
        us_privacy: CCPAUserPrivacy(us_privacy ?? ''),
        'X-Device-User-Agent': ua,
        'X-Device-Referer': window.location.href,
      }),
    );

    return targetingQueryParams.toString().length > 0 ?
        new URL(
          `${TRITON_VAST_BASE_URL}?${targetingQueryParams.toString()}`,
        ).toString()
      : null;
  } catch {
    console.error(PlayerError.Targeting.message);
  }
  return null;
};

export const buildCustomPreRollUrl = ({
  ampPrerollUrl,
  iu,
  seed,
  prerollTargeting,
}: {
  ampPrerollUrl: string;
  iu: string;
  seed?: string;
  prerollTargeting?: CustomPrerollAdTargeting | null;
}) => {
  try {
    const [, query] = ampPrerollUrl.split('?');
    const vastParams = new URLSearchParams(query);

    vastParams.set('iu', iu);

    for (const [key, value] of Object.entries(DEFAULT_PREROLL_PARAMS)) {
      vastParams.set(key, value);
    }

    const cust_params = new URLSearchParams(
      vastParams.get('cust_params') ?? '',
    );
    cust_params.set('ccrcontent1', 'null');
    cust_params.set('ccrcontent2', 'null');
    cust_params.set('ccrcontent3', 'null');
    cust_params.set('ccrpos', '7005');
    cust_params.set('source', 'null');
    if (seed) {
      cust_params.set('seed', seed);
    }
    if (prerollTargeting) {
      for (const [key, value] of Object.entries(prerollTargeting)) {
        if (value) {
          cust_params.set(key, String(value));
        }
      }
    }

    vastParams.set('cust_params', cust_params.toString());

    return new URL(`${GAM_VAST_BASE_URL}?${vastParams.toString()}`).toString();
  } catch (error: unknown) {
    console.log('Could not build custom preroll url', error);
  }
};

export const getLiveAdUnit = memoize(
  ({
    provider,
    market,
    callLetters,
    dfpInstanceId,
  }: {
    provider?: string;
    market?: string;
    callLetters: string;
    dfpInstanceId?: number | null;
  }) => {
    if (!dfpInstanceId) return;

    const providerAlias =
      !provider || /clear\schannel/i.test(provider) ?
        'ccr'
      : provider?.toLowerCase().slice(0, 3);

    const marketAlias =
      market ? market.toLowerCase().replaceAll('-', '.') : undefined;

    const postfix = Math.random() > 0.5 ? 'n' : undefined;

    return `/${[
      String(dfpInstanceId),
      [providerAlias, marketAlias, postfix].filter(Boolean).join('.'),
      callLetters?.toLowerCase(),
    ]
      .filter(Boolean)
      .join('/')}`;
  },
);
