import { type AmpClient, createAmpClient } from '@iheartradio/web.api/amp';

import { ApiTokenCookieJar } from '~app/lib/remix-shared-isomorphic';

const { serialize } = ApiTokenCookieJar;

export const amp = createAmpClient({
  throwOnUnknownStatus: true,
  throwOnErrorStatus: true,
  tokenCookieSerializer: serialize,
});

export type { AmpClient };
